<template>
  <el-container class="body-main body-main-login">

    <el-row class="m-login">
      <el-col :span="12" class="left">
        <div class="top">
          <h2>软基处理监测系统</h2>
        </div>
      </el-col>
      <el-col :span="12" class="right">
        <div class="login">
          <h2>欢迎登录</h2>
          <el-form label-width="0" :model="formLabelAlign" class="form">
            <el-form-item class="item" required>
              <span class="icon icon-phone"></span>
              <el-input class="input" placeholder="请在此输入用户名" v-model="formLabelAlign.userName"></el-input>
            </el-form-item>
            <el-form-item class="item" required>
              <span class="icon icon-password"></span>
              <el-input class="input" type="password" placeholder="请在此输入密码" v-model="formLabelAlign.passWord"></el-input>
            </el-form-item>

            <el-form-item class="item" required>
              <el-checkbox-group v-model="formLabelAlign.isChecked" text-color="#0B85E3">
                <el-checkbox :false-label="0" :true-label="1" label="记住账号" name="isChecked"></el-checkbox>
              </el-checkbox-group>
            </el-form-item>
            <el-button type="primary" @click="submit()" class="submit">登录</el-button>
          </el-form>
        </div>
      </el-col>
    </el-row>
    <a href="https://beian.miit.gov.cn " class="num" target="_blank">浙ICP备14033869号-3</a>
  </el-container>
</template>

<script>
import { login, queryGroups } from "@/api/api";


export default {
  name: "Login",
  data() {
    return {

      labelPosition: 'right',
      formLabelAlign: {
        userName: '',
        passWord: '',
        isChecked: false
      }
    };
  },
  watch: {
    isChecked(val) {
      val ? this.setCookie() : this.clearCookie();
    }
  },
  mounted() {
    this.getCookie();
  },
  methods: {
    setCookie(username, password, nums, token) {
      const time = new Date()
      time.setTime(time.getTime() + 24 * 60 * 60 * 1000 * nums);
      //加上window.btoa是为了对用户名和密码进行编码，不让cookie明文展示
      window.document.cookie = `userName=${username}; path=/; expires=${time.toGMTString()}`;
      window.document.cookie = `passWord=${window.btoa(password)}; path=/; expires=${time.toGMTString()}`;
      window.document.cookie = `isChecked=${true}; path=/; expires=${time.toGMTString()}`;
      time.setTime(time.getTime() + 24 * 60 * 60 * 1000 * 15);
      window.document.cookie = `token=${token};path=/;domain=hzcjkj.com;expires=${time.toGMTString()}`
    },
    //1首先判断用户名密码是否为空，
    //2如果为不为空在调用接口
    //3判断输入的用户名和密码是否正确，正确登录成功，不正确就提示输入用户名或密码错误，重新输入
    // submit 点击登录
    submit() {
      if (this.formLabelAlign.userName == '' || this.formLabelAlign.passWord == '') {
        this.$message.error('用户名或密码不能为空');
      } else {
        login(this.formLabelAlign.userName, this.formLabelAlign.passWord).then(res => {
          if (res.success == false) {
            this.$message.error('用户名或密码错误');
          } else {
            sessionStorage.clear()
            sessionStorage['accountType'] = res.ClientUser.accountType
            sessionStorage['token'] = res.token
            sessionStorage['name'] = res.ClientUser.name
            this.setCookie(this.formLabelAlign.userName, this.formLabelAlign.passWord, 7, res.token)
            this.$router.push('/index');
          }
        })
      }
    },
    getCookie() {
      if (document.cookie.length > 0) {
        const cookieArr1 = document.cookie.split(";");
        for (let i in cookieArr1) {
          const cookieArr2 = cookieArr1[i].split("=");
          //避免输入中含有空格，消除文本框空格
          cookieArr2[0] = cookieArr2[0].replace(/\s+/g, "");
          cookieArr2[1] = cookieArr2[1].replace(/\s+/g, "");
          if (cookieArr2[0] == "userName") {
            //读取cookie然后进行解码
            this.formLabelAlign.userName = cookieArr2[1];
          } else if (cookieArr2[0] == "passWord") {
            this.formLabelAlign.passWord = window.atob(cookieArr2[1]);
          } else if (cookieArr2[0] == "checked") {
            this.formLabelAlign.isChecked = JSON.parse(cookieArr2[1])
          }
        }
        if (!this.formLabelAlign.userName == '' && !this.formLabelAlign.passWord == '') {
          this.submit()
        }

      }
    },
    /* 清除cookie */
    clearCookie() {
      this.setCookie("", "", -1);
    }
  },
  created() {
    // 按回车键实现登录的效果
    var _self = this;
    document.onkeydown = function (e) {
      var key = window.event.keyCode;
      if (_self.$route.path == '/login' && (key == 13)) {//验证在登录界面和按得键是回车键enter
        _self.submit("/index");
      }
    };
  },



};
</script>
<style scoped>
.num {
  color: #fff;
  height: 30px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
.num:hover {
  color: rgb(141, 141, 141);
}
.icon-phone:before {
  content: none;
}
</style>
